<template>
    <!-- form modal -->
    <div
        tabindex="0"
        @keydown.esc="toggleModalVisibility()"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showAdditonalUserModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="createCategoryRef">
            <div class="box-border-bottom f3 pa3 b">{{ plansName[selectedPlan] }} (Additional Users)</div>
            <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                <!-- No of additional users -->
                <div class="flex justify-between pa3">
                    <div class="flex flex-column">
                        <div class="f4">
                            <p>No of additional users</p>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="f3">
                            <span class="f4"
                                >({{ state.additionalUsers }}) x
                                {{ formatAmount(plansAdditionaUserlCost[selectedPlan], $store.state.Settings?.currency) }}</span
                            >
                            <span class="f6">/month</span>
                        </div>
                    </div>
                </div>
                <div class="ph5">
                    <!-- Product name -->
                    <div class="pt1">
                        <div class="pv2 font-w1"><label for="name"></label></div>
                        <input
                            required
                            type="number"
                            id="additional_users"
                            name="additional_users"
                            placeholder="Enter Additional User"
                            v-model="state.additionalUsers"
                            min="1"
                        />
                    </div>
                    <!-- Calculated Total Amount -->
                    <div class="pt1">
                        <div>
                            <div class="pv2 font-w1"><label for="name"></label></div>
                            <span class="f4"
                                >Total Amount:
                                {{
                                    formatAmount(
                                        plansAdditionaUserlCost[selectedPlan] * state.additionalUsers,
                                        $store.state.Settings?.currency
                                    )
                                }}</span
                            >
                        </div>
                    </div>
                    <div class="flex items-center justify-center w-100">
                        <button class="mt4" type="submit" :disabled="state.disableSubmitButton">
                            {{ state.disableSubmitButton ? 'Processing...' : 'Make Payment' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { businessPlans, plansName, plansCost, plansAdditionaUserlCost, formatAmount } from '@/utils/lib'

export default {
    name: 'PlanBox',

    setup(props) {
        const createCategoryRef = ref(null)
        const store = useStore()
        const showAdditonalUserModal = computed(() => store?.state.Settings.showAdditonalUserModal)
        const selectedPlan = computed(() => store?.state.Settings.selectedPlan)

        const state = reactive({
            additionalUsers: 1,
            disableSubmitButton: false,
            name: ''
        })

        const toggleModalVisibility = (payload = false) => {
            if (!payload) state.additionalUsers = 0
            store.dispatch('Settings/showAdditonalUserModal', payload)
        }

        const onSubmit = async () => {
            if (state.additionalUsers <= 0) {
                await store.dispatch('Alert/setAlert', { message: 'Please add number of users', status: false })
                return
            }
            state.disableSubmitButton = true

            const planData = {
                hasAdditionalUsers: state.additionalUsers > 0,
                // hasAdditionalBusiness: hasAdditionalBusiness,
                additionalUsers: state.additionalUsers,
                // additionalBusiness: additionalBusiness,
                businessPlan: selectedPlan.value
            }

            store.dispatch('Settings/createPlanAdditionalUser', planData).then(() => {
                toggleModalVisibility(false)
                state.disableSubmitButton = false
            })
        }

        // close when click outside modal

        const handleOnClickOutsideModal = e => {
            if (createCategoryRef.value && !createCategoryRef.value.contains(e.target)) {
                toggleModalVisibility()
            }
        }

        onMounted(() => store.dispatch('Inventory/getAllCategories'))

        const onShowCreatePlan = (payload = true) => {
            store.dispatch('Settings/showAdditonalUserModal', payload)
        }

        return {
            state,
            showAdditonalUserModal,
            toggleModalVisibility,
            onSubmit,
            createCategoryRef,
            handleOnClickOutsideModal,
            onShowCreatePlan,
            businessPlans,
            plansName,
            plansCost,
            plansAdditionaUserlCost,
            selectedPlan,
            formatAmount
        }
    }
}
</script>

<style scoped></style>
