<template>
    <settings-wrapper>
        <template v-slot:child>
            <PlanBox />
            <additional-user-modal />
            <extend-plan-modal />

            <div class="wrap1">
                <!-- Begin Your Code -->
                <template v-if="isLoading"><Spinner /></template>

                <section v-else>
                    <section class="pricing-section">
                        <div v-if="userData?.isPlanExpired && state.isPaid" class="alert alert-danger w-80 center">
                            Your plan has expired, please renew or upgrade to a new plan.
                        </div>
                        <div v-if="userData?.isPlanExpired && !state.isPaid" class="alert alert-warning w-80 center">
                            Sorry, your last transaction was not successful, please try again.
                        </div>
                        <div class="pricing pricing-theme">
                            <div :class="state.paymentPlanType === 1 ? 'pricing-item subscribe-plan' : 'pricing-item'">
                                <h3 class="pricing-title">Free Plan</h3>
                                <div class="pricing-price"><span class="pricing-currency"></span>FREE</div>
                                <p class="pricing-sentence">Ideal for new new businesses, online ventures, and side hustles</p>
                                <ul class="pricing-feature-list">
                                    <li class="pricing-feature">1 User</li>
                                    <li class="pricing-feature">Track income & expense</li>
                                    <li class="pricing-feature">Unlimited invoicing</li>
                                    <li class="pricing-feature">Receipt generation</li>
                                    <li class="pricing-feature">Send Outstanding Reminders and Get Paid faster</li>
                                </ul>
                                <button
                                    v-if="state.paymentPlanType !== 1 && userData?.isPlanExpired"
                                    @click="selectPlan('free')"
                                    class="pricing-action"
                                >
                                    Downgrade
                                </button>
                                <button v-else-if="state.paymentPlanType === 1" class="pricing-action" disabled>
                                    Current Plan
                                </button>
                            </div>
                            <!-- .pricing-item -->

                            <div v-if="state.paymentPlanType === 2"
                                :class="
                                    state.paymentPlanType === 2 && state.isPaid && !state.hasExpired
                                        ? 'pricing-item subscribe-plan'
                                        : 'pricing-item'
                                "
                            >
                                <h3 class="pricing-title">Small Business Plan</h3>
                                <div class="pricing-price"><span class="pricing-currency"></span>N5,000</div>
                                <p class="pricing-sentence">Small business solution</p>
                                <ul class="pricing-feature-list">
                                    <li class="pricing-feature">All features of FREE</li>
                                    <li class="pricing-feature">Up to 500 products</li>
                                    <li class="pricing-feature">Unlimited entities</li>
                                    <li class="pricing-feature">Point of Sale</li>
                                    <li class="pricing-feature">Pricing Model</li>
                                    <li class="pricing-feature">2 users (N1000 for additional users)</li>
                                    <li class="pricing-feature">Financial reports - Cashflow Statement</li>
                                    <li class="pricing-feature">Unlimited Invoicing</li>
                                    <li class="pricing-feature">Multi-business/sales channel (+$8 for additional business)</li>
                                </ul>
                                <button
                                    v-if="state.paymentPlanType < 2 && !userData?.isPlanExpired"
                                    @click="selectPlan('small')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 2 && state.isPaid && userData?.isPlanExpired"
                                    @click="selectPlan('small')"
                                    class="pricing-action"
                                >
                                    Renew
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 2 && !state.isPaid"
                                    @click="verifyPayment()"
                                    class="pricing-action"
                                    :disabled="state.verifying"
                                >
                                    {{ state.verifying ? 'Verifying...' : 'Payment not verified, click here to activate' }}
                                </button>
                                <button
                                    v-if="state.paymentPlanType > 2 && userData?.isPlanExpired"
                                    @click="selectPlan('small')"
                                    class="pricing-action"
                                >
                                    Downgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 2 && state.isPaid && !userData?.isPlanExpired"
                                    class="pricing-action"
                                    @click="selectPlan('small')"
                                >
                                    Current Plan
                                </button>
                                <div v-if="state.paymentPlanType === 2 && state.isPaid && !userData?.isPlanExpired" class="pb2">
                                    <a href="#" @click.prevent="onShowExtendPlanModal('small')">Extend Plan</a>
                                    <div>
                                        <a href="#" @click.prevent="cancelPlan">Cancel Plan</a>
                                    </div>
                                </div>
                            </div>
                            <!-- .pricing-item -->

                            <div v-if="state.paymentPlanType === 3"
                                :class="
                                    state.paymentPlanType === 3 && state.isPaid && !state.hasExpired
                                        ? 'pricing-item subscribe-plan'
                                        : 'pricing-item'
                                "
                            >
                                <h3 class="pricing-title">Medium Enterprise Plan</h3>
                                <div class="pricing-price"><span class="pricing-currency"></span>N50,000</div>
                                <p class="pricing-sentence">Medium business solution</p>
                                <ul class="pricing-feature-list">
                                    <li class="pricing-feature">All Small Business plans feature</li>
                                    <li class="pricing-feature">unlimited inventory</li>
                                    <li class="pricing-feature">Insights & Business Analysis</li>
                                    <li class="pricing-feature">Asset management</li>
                                    <li class="pricing-feature">Loan management</li>
                                    <li class="pricing-feature">Sole Trader/Limited Liability/Partnership Accounting</li>
                                    <li class="pricing-feature">10 users (+ N2500 for additional users)</li>
                                    <li class="pricing-feature">Financial analysis</li>
                                    <li class="pricing-feature">Free Multi-business</li>
                                </ul>
                                <button
                                    v-if="state.paymentPlanType < 3 && (userData?.isPlanExpired || !userData?.isPlanExpired)"
                                    @click="selectPlan('medium')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 3 && state.isPaid && userData?.isPlanExpired"
                                    @click="selectPlan('medium')"
                                    class="pricing-action"
                                >
                                    Renew
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 3 && !state.isPaid"
                                    @click="verifyPayment()"
                                    class="pricing-action"
                                    :disabled="state.verifying"
                                >
                                    {{ state.verifying ? 'Verifying...' : 'Payment not verified, click here to activate' }}
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 3 && state.isPaid && !userData?.isPlanExpired"
                                    class="pricing-action"
                                    disabled
                                >
                                    Current Plan
                                </button>
                                <div v-if="state.paymentPlanType === 3 && state.isPaid && !userData?.isPlanExpired" class="pb2">
                                    <a href="#" @click.prevent="onShowExtendPlanModal('medium')">Extend Plan</a>
                                    <div>
                                        <a href="#" @click.prevent="cancelPlan">Cancel Plan</a>
                                    </div>
                                </div>
                            </div>
                            <!-- .pricing-item -->

                            <div
                                :class="
                                    state.paymentPlanType === 4 && state.isPaid && !state.hasExpired
                                        ? 'pricing-item subscribe-plan'
                                        : 'pricing-item'
                                "
                            >
                                <h3 class="pricing-title">Basic Plan</h3>
                                <div class="pricing-price"><span class="pricing-currency"></span>N5,000</div>
                                <p class="pricing-sentence">Designed for freelance contractors and small businesses</p>
                                <ul class="pricing-feature-list">
                                    <li class="pricing-feature">1 user</li>
                                    <li class="pricing-feature">All benefits of Free</li>
                                    <li class="pricing-feature">Send custom invoices & quotes</li>
                                    <li class="pricing-feature">Inventory management</li>
                                    <li class="pricing-feature">Contact/Customer Management</li>
                                    <li class="pricing-feature">Single business only</li>
                                    <li class="pricing-feature">Paystack Integration</li>
                                    <li class="pricing-feature">Virtual Onboarding</li>
                                    <li class="pricing-feature">Manage bills & Payments</li>
                                </ul>
                                <button
                                    v-if="state.paymentPlanType < 4 && !userData?.isPlanExpired"
                                    @click="selectPlan('basic')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 4 && state.isPaid && userData?.isPlanExpired"
                                    @click="selectPlan('basic')"
                                    class="pricing-action"
                                >
                                    Renew
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 4 && !state.isPaid"
                                    @click="verifyPayment()"
                                    class="pricing-action"
                                    :disabled="state.verifying"
                                >
                                    {{ state.verifying ? 'Verifying...' : 'Payment not verified, click here to activate' }}
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType > 4 && userData?.isPlanExpired"
                                    @click="selectPlan('basic')"
                                    class="pricing-action"
                                >
                                    Downgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 4 && state.isPaid && !userData?.isPlanExpired"
                                    class="pricing-action"
                                    @click="selectPlan('basic')"
                                >
                                    Current Plan
                                </button>
                                <button
                                    v-else-if="(state.paymentPlanType === 2 || state.paymentPlanType === 3) && state.isPaid && !userData?.isPlanExpired"
                                    @click="selectPlan('basic')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <div v-if="state.paymentPlanType === 4 && state.isPaid && !userData?.isPlanExpired" class="pb2">
                                    <a href="#" @click.prevent="onShowExtendPlanModal('basic')">Extend Plan</a>
                                    <div>
                                        <a href="#" @click.prevent="cancelPlan">Cancel Plan</a>
                                    </div>
                                </div>
                            </div>
                            <!-- .pricing-item -->

                            <div
                                :class="
                                    state.paymentPlanType === 5 && state.isPaid && !state.hasExpired
                                        ? 'pricing-item subscribe-plan'
                                        : 'pricing-item'
                                "
                            >
                                <h3 class="pricing-title">Store Plan</h3>
                                <div class="pricing-price"><span class="pricing-currency"></span>N50,000</div>
                                <p class="pricing-sentence">Perfect for store owners with advanced needs</p>
                                <ul class="pricing-feature-list">
                                    <li class="pricing-feature">Point of Sale</li>
                                    <li class="pricing-feature">Advanced Inventory Management</li>
                                    <li class="pricing-feature">Multi-store</li>
                                    <li class="pricing-feature">Multi user (3 Free, $5/N5,000 pm for additional user)</li>
                                    <li class="pricing-feature">Accounting: P&L, Sales, Expenses, Inventory Management</li>
                                    <li class="pricing-feature">Paystack Integration</li>
                                    <li class="pricing-feature">Paid In-store Launch/Onboarding service</li>
                                    <li class="pricing-feature">Purchase Order</li>
                                    <li class="pricing-feature">Inventory Transfer</li>
                                    <li class="pricing-feature">Data Import & Export</li>
                                    <li class="pricing-feature">Product Variants</li>
                                    <li class="pricing-feature">Production Management</li>
                                </ul>
                                <button
                                    v-if="state.paymentPlanType < 5 && !userData?.isPlanExpired"
                                    @click="selectPlan('store')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 5 && state.isPaid && userData?.isPlanExpired"
                                    @click="selectPlan('store')"
                                    class="pricing-action"
                                >
                                    Renew
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 5 && !state.isPaid"
                                    @click="verifyPayment()"
                                    class="pricing-action"
                                    :disabled="state.verifying"
                                >
                                    {{ state.verifying ? 'Verifying...' : 'Payment not verified, click here to activate' }}
                                </button>
                                <button
                                    v-if="state.paymentPlanType > 5 && userData?.isPlanExpired"
                                    @click="selectPlan('store')"
                                    class="pricing-action"
                                >
                                    Downgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 5 && state.isPaid && !userData?.isPlanExpired"
                                    class="pricing-action"
                                    @click="selectPlan('store')"
                                >
                                    Current Plan
                                </button>
                                <button
                                    v-else-if="(state.paymentPlanType === 2 || state.paymentPlanType === 3) && state.isPaid && !userData?.isPlanExpired"
                                    @click="selectPlan('store')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <div v-if="state.paymentPlanType === 5 && state.isPaid && !userData?.isPlanExpired" class="pb2">
                                    <a href="#" @click.prevent="onShowExtendPlanModal('store')">Extend Plan</a>
                                    <div>
                                        <a href="#" @click.prevent="cancelPlan">Cancel Plan</a>
                                    </div>
                                </div>
                            </div>
                            <!-- .pricing-item -->

                            <div
                                :class="
                                    state.paymentPlanType === 6 && state.isPaid && !state.hasExpired
                                        ? 'pricing-item subscribe-plan'
                                        : 'pricing-item'
                                "
                            >
                                <h3 class="pricing-title">Professional Plan</h3>
                                <div class="pricing-price"><span class="pricing-currency"></span>N50,000</div>
                                <p class="pricing-sentence">Tailored for professional businesses with advanced accounting requirements</p>
                                <ul class="pricing-feature-list">
                                    <li class="pricing-feature">Multi-business</li>
                                    <li class="pricing-feature">Multi user (3 Free, $5/N5,000 pm for additional user)</li>
                                    <li class="pricing-feature">Production Management</li>
                                    <li class="pricing-feature">Paystack Integration</li>
                                    <li class="pricing-feature">Paid In-office Launch/Onboarding service</li>
                                    <li class="pricing-feature">Purchase Order</li>
                                    <li class="pricing-feature">Data Import & Export</li>
                                    <li class="pricing-feature">Advanced Accounting
                                        <ul class="pricing-feature-list">
                                            <li class="pricing-feature">P&L</li>
                                            <li class="pricing-feature">Balance Sheet</li>
                                            <li class="pricing-feature">Cash-flow Management</li>
                                            <li class="pricing-feature">Asset management</li>
                                            <li class="pricing-feature">Chart of Accounts</li>
                                            <li class="pricing-feature">Journal entry</li>
                                            <li class="pricing-feature">Bank Transfers</li>
                                            <li class="pricing-feature">Bank Reconciliation</li>
                                            <li class="pricing-feature">Trial Balance</li>
                                        </ul>
                                    </li>
                                    <li class="pricing-feature">Advanced expense management</li>
                                    <li class="pricing-feature">Multi-currency</li>
                                </ul>
                                <button
                                    v-if="state.paymentPlanType < 6 && !userData?.isPlanExpired"
                                    @click="selectPlan('professional')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 6 && state.isPaid && userData?.isPlanExpired"
                                    @click="selectPlan('professional')"
                                    class="pricing-action"
                                >
                                    Renew
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 6 && !state.isPaid"
                                    @click="verifyPayment()"
                                    class="pricing-action"
                                    :disabled="state.verifying"
                                >
                                    {{ state.verifying ? 'Verifying...' : 'Payment not verified, click here to activate' }}
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType > 6 && userData?.isPlanExpired"
                                    @click="selectPlan('professional')"
                                    class="pricing-action"
                                >
                                    Downgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 6 && state.isPaid && !userData?.isPlanExpired"
                                    class="pricing-action"
                                    @click="selectPlan('professional')"
                                >
                                    Current Plan
                                </button>
                                <button
                                    v-else-if="(state.paymentPlanType === 2 || state.paymentPlanType === 3) && state.isPaid && !userData?.isPlanExpired"
                                    @click="selectPlan('professional')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <div v-if="state.paymentPlanType === 6 && state.isPaid && !userData?.isPlanExpired" class="pb2">
                                    <a href="#" @click.prevent="onShowExtendPlanModal('professional')">Extend Plan</a>
                                    <div>
                                        <a href="#" @click.prevent="cancelPlan">Cancel Plan</a>
                                    </div>
                                </div>
                            </div>
                            <!-- .pricing-item -->

                            <div
                                :class="
                                    state.paymentPlanType === 7 && state.isPaid && !state.hasExpired
                                        ? 'pricing-item subscribe-plan'
                                        : 'pricing-item'
                                "
                            >
                                <h3 class="pricing-title">Enterprise Plan</h3>
                                <div class="pricing-price"><span class="pricing-currency"></span>N100,000</div>
                                <p class="pricing-sentence">Suitable for large enterprises with complex needs and multiple businesses</p>
                                <ul class="pricing-feature-list">
                                    <li class="pricing-feature">10 Free User ($5/N5,000 pm for additional user)</li>
                                    <li class="pricing-feature">Point of Sale</li>
                                    <li class="pricing-feature">Advanced Inventory Management</li>
                                    <li class="pricing-feature">Multi-business</li>
                                    <li class="pricing-feature">Multi user (3 Free, $5/N5,000 pm for additional user)</li>
                                    <li class="pricing-feature">Paystack Integration</li>
                                    <li class="pricing-feature">Purchase Order</li>
                                    <li class="pricing-feature">Inventory Transfer</li>
                                    <li class="pricing-feature">Data Import & Export</li>
                                    <li class="pricing-feature">Product Variants</li>
                                    <li class="pricing-feature">Advanced Reporting</li>
                                    <li class="pricing-feature">Dedicated Account Manager</li>
                                    <li class="pricing-feature">Free In-store Launch services (one off and Lagos only)</li>
                                    <li class="pricing-feature">Advanced Accounting
                                        <ul class="pricing-feature-list">
                                            <li class="pricing-feature">Cashflow Statement</li>
                                            <li class="pricing-feature">Budgeting</li>
                                            <li class="pricing-feature">P&L</li>
                                            <li class="pricing-feature">Balance Sheet</li>
                                            <li class="pricing-feature">Cash-flow Management</li>
                                            <li class="pricing-feature">Chart of Accounts</li>
                                            <li class="pricing-feature">Journal entry</li>
                                            <li class="pricing-feature">Bank Transfers</li>
                                            <li class="pricing-feature">Bank Reconciliation</li>
                                            <li class="pricing-feature">Trial Balance</li>
                                        </ul>
                                    </li>
                                    <li class="pricing-feature">Production Management</li>
                                    <li class="pricing-feature">Asset management</li>
                                    <li class="pricing-feature">Advanced expense management</li>
                                    <li class="pricing-feature">Multi-currency</li>
                                    <li class="pricing-feature">Project Accounting</li>
                                    <li class="pricing-feature">Recurring invoices & bills</li>
                                </ul>
                                <button
                                    v-if="state.paymentPlanType < 7 && !userData?.isPlanExpired"
                                    @click="selectPlan('enterprise')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 7 && state.isPaid && userData?.isPlanExpired"
                                    @click="selectPlan('enterprise')"
                                    class="pricing-action"
                                >
                                    Renew
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 7 && !state.isPaid"
                                    @click="verifyPayment()"
                                    class="pricing-action"
                                    :disabled="state.verifying"
                                >
                                    {{ state.verifying ? 'Verifying...' : 'Payment not verified, click here to activate' }}
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType > 7 && userData?.isPlanExpired"
                                    @click="selectPlan('enterprise')"
                                    class="pricing-action"
                                >
                                    Downgrade
                                </button>
                                <button
                                    v-else-if="state.paymentPlanType === 7 && state.isPaid && !userData?.isPlanExpired"
                                    class="pricing-action"
                                    @click="selectPlan('enterprise')"
                                >
                                    Current Plan
                                </button>
                                <button
                                    v-else-if="(state.paymentPlanType === 2 || state.paymentPlanType === 3) && state.isPaid && !userData?.isPlanExpired"
                                    @click="selectPlan('enterprise')"
                                    class="pricing-action"
                                >
                                    Upgrade
                                </button>
                                <div v-if="state.paymentPlanType === 7 && state.isPaid && !userData?.isPlanExpired" class="pb2">
                                    <a href="#" @click.prevent="onShowExtendPlanModal('enterprise')">Extend Plan</a>
                                    <div>
                                        <a href="#" @click.prevent="cancelPlan">Cancel Plan</a>
                                    </div>
                                </div>
                            </div>
                            <!-- .pricing-item -->
                        </div>
                        <!-- .pricing .pricing-theme -->
                    </section>
                    <!-- .pricing-section -->
                    <!-- compare pricing -->
                    <section class="comparePricing">
                        <div>
                            <!-- <div class="combtn">
                                <button class="comparebtn" @click="toggleTable">Compare Pricing</button>
                            </div> -->
                            <table v-if="showTable" class="pricingTable">
                                <tr>
                                    <th></th>
                                    <th>Micro Business Plan</th>
                                    <th>Small Business Plan</th>
                                    <th>Medium Enterprise Plan</th>
                                </tr>
                                <tr>
                                    <th>Monthly</th>
                                    <td>Free</td>
                                    <td>N5000</td>
                                    <td>N50000</td>
                                </tr>
                                <tr>
                                    <th>Yearly</th>
                                    <td>Free</td>
                                    <td>N50000</td>
                                    <td>N500000</td>
                                </tr>
                                <tr>
                                    <th>Unlimited transaction recording</th>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Inventory Management</th>
                                    <td>(up to 50 products)</td>
                                    <td>Up to 500 products</td>
                                    <td>unlimited inventory</td>
                                </tr>
                                <tr>
                                    <th>Debtors & creditors</th>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Customer Management</th>
                                    <td>(up to 20 customers)</td>
                                    <td>Unlimited entities</td>
                                    <td>Unlimited entities</td>
                                </tr>
                                <tr>
                                    <th>Report Generation: P&L, Balance sheet</th>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>E-Invoicing</th>
                                    <td>(5 invoices per month)</td>
                                    <td>Unlimited Invoicing</td>
                                    <td>Unlimited Invoicing</td>
                                </tr>
                                <tr>
                                    <th>Integrations</th>
                                    <td>✓</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>User</th>
                                    <td>1 user</td>
                                    <td>2 users (N1000 for additional users)</td>
                                    <td>10 users (+ N2500 for additional users)</td>
                                </tr>
                                <tr>
                                    <th>Point of Sale</th>
                                    <td>x</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Pricing Model</th>
                                    <td>x</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Financial reports - Cashflow Statement</th>
                                    <td>x</td>
                                    <td>✓</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Multi-business/sales channel</th>
                                    <td>x</td>
                                    <td>(+$8 for additional business)</td>
                                    <td>Free Multi-business</td>
                                </tr>
                                <tr>
                                    <th>Insights & Business Analysis</th>
                                    <td>x</td>
                                    <td>x</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Loan management</th>
                                    <td>x</td>
                                    <td>x</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Sole Trader/Limited Liability/Partnership Accounting</th>
                                    <td>x</td>
                                    <td>x</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Financial analysis</th>
                                    <td>x</td>
                                    <td>x</td>
                                    <td>✓</td>
                                </tr>
                                <tr>
                                    <th>Asset management</th>
                                    <td>x</td>
                                    <td>x</td>
                                    <td>✓</td>
                                </tr>
                            </table>
                        </div>
                    </section>
                    <!-- end of compare pricing -->
                </section>
                <!-- End Your Code -->
            </div>
            <!-- .wrap -->
        </template>
    </settings-wrapper>
</template>

<script>
import { useStore } from 'vuex'
import { computed, reactive, watch, onMounted, ref } from 'vue'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import OrgData from '@/components/OrgData'
import Swal from 'sweetalert2'
import Spinner from '@/components/Spinner'
import PlanBox from '@/views/Settings/widgets/PlanBox'
import AdditionalUserModal from '@/views/Settings/widgets/AdditionalUserModal'
import ExtendPlanModal from '@/views/Settings/widgets/ExtendPlanModal'

import { businessPlans } from '@/utils/lib'

export default {
    name: 'Plan',
    components: { SettingsWrapper, OrgData, Spinner, PlanBox, ExtendPlanModal, AdditionalUserModal },

    setup() {
        const store = useStore()
        const isLoading = computed(() => store.state.Settings.isLoading)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const userData = computed(() => store.state?.Auth?.userData)
        const showTable = ref(false)

        const initaialState = {
            paymentPlanType: 0,
            isPaid: false,
            hasExpired: false,
            activated: false,
            verifying: false,
            isDeclined: false
        }
        function toggleTable() {
            showTable.value = !showTable.value
        }

        const state = reactive(initaialState)

        // watch plan
        watch(plan, value => {
            if (value) {
                state.isPaid = value.isPaid
                state.hasExpired = value.hasExpired
                state.activated = value?.status === 'success'
                if (value.businessPlan === 'Micro Business Plan') {
                    state.paymentPlanType = 1
                } else if (value.businessPlan === 'Small Business Plan') {
                    state.paymentPlanType = 2
                } else if (value.businessPlan === 'Medium Enterprise Plan') {
                    state.paymentPlanType = 3
                } else if (value.businessPlan === 'Basic Plan') {
                    state.paymentPlanType = 4
                } else if (value.businessPlan === 'Store Plan') {
                    state.paymentPlanType = 5
                } else if (value.businessPlan === 'Professional Plan') {
                    state.paymentPlanType = 6
                } else if (value.businessPlan === 'Enterprise Plan') {
                    state.paymentPlanType = 7
                }
            }
        })

        onMounted(async () => {
            store.dispatch('Settings/getOrganization')
            store.dispatch('Settings/getPlan')
            await store.dispatch('Auth/getUserData')
        })

        const selectPlan = async plan => {
            let hasAdditionalUsers = false
            let hasAdditionalBusiness = false
            let additionalUsers = 0
            let additionalBusiness = 0
            let businessPlan = businessPlans[plan]
            let planData = null
            if (plan === 'free') {
                hasAdditionalUsers = false
                hasAdditionalBusiness = false
                additionalUsers = 0
                additionalBusiness = 0

                planData = {
                    hasAdditionalUsers: hasAdditionalUsers,
                    hasAdditionalBusiness: hasAdditionalBusiness,
                    additionalUsers: additionalUsers,
                    additionalBusiness: additionalBusiness,
                    businessPlan: businessPlan
                }

                store.dispatch('Settings/createPlan', planData).then(resp => {
                    store.dispatch('getUserData')
                    if (resp?.success) {
                        Swal.fire({
                            title: 'Success',
                            text: 'Redirecting to Paystack to process your subscription',
                            type: 'success',
                            confirmButtonText: 'OK'
                        })
                        // state.disableButton = false
                    } else {
                        // state.disableButton = false
                    }
                })
            } else {
                await store.dispatch('Settings/setIsTransactionDeclined', false)
                await store.dispatch('Settings/setPlan', businessPlan)
                onShowCreatePlan(true)
            }
        }

        const onShowCreatePlan = (payload = true) => {
            store.dispatch('Settings/showCreatePlan', payload)
        }

        const cancelPlan = () => {
            Swal.fire({
                    title: `Cancel Plan`,
                    text: `Are you sure you want to cancel your current plan?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Yes, Continue',
                    denyButtonText: `No, Ignore`
                }).then(result => {
                    if (result.isConfirmed) {
                        selectPlan('free')
                    }
                })
        }

        const onShowAdditonalUserModal = async (plan, payload = true) => {
            let businessPlan = businessPlans[plan]
            await store.dispatch('Settings/setPlan', businessPlan)
            store.dispatch('Settings/showAdditonalUserModal', payload)
        }

        const onShowExtendPlanModal = async (plan, payload = true) => {
            let businessPlan = businessPlans[plan]
            await store.dispatch('Settings/setPlan', businessPlan)
            store.dispatch('Settings/showExtendPlanModal', payload)
        }

        const verifyPayment = async reference => {
            state.verifying = true
            await store.dispatch('Settings/setIsTransactionDeclined', false)
            if (!reference && plan.value) {
                if (state.activated) {
                    store
                        .dispatch('Alert/setAlert', {
                            status: true,
                            message: 'Payment already activated'
                        })
                        .then(_ => {
                            state.verifying = false
                        })
                    return
                }

                const activatePayload = {
                    reference: plan.value.payment_reference,
                    type: 'activate'
                }

                await store.dispatch('Settings/verifyPayment', activatePayload).then(async data => {
                    state.verifying = false
                    if (data.data.status === 'failed' && data.data.gateway_response === 'Declined') {
                        state.isDeclined = true
                        await store.dispatch('Settings/setPlan', state.paymentPlanType)
                        await store.dispatch('Settings/setIsTransactionDeclined', true)
                        onShowCreatePlan(true)
                    }
                })
            } else if (!reference && !plan.value) {
                return
            } else {
                const verifyPayload = {
                    reference,
                    type: 'verify'
                }
                await store.dispatch('Settings/verifyPayment', verifyPayload).then(_ => {
                    state.verifying = false
                })
            }
        }

        return {
            state,
            userData,
            isLoading,
            selectPlan,
            onShowCreatePlan,
            onShowAdditonalUserModal,
            onShowExtendPlanModal,
            verifyPayment,
            showTable,
            toggleTable,
            plan,
            cancelPlan,
        }
    }
}
</script>

<style scoped>
* {
    box-sizing: border-box !important;
}
.combtn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.comparePricing {
    margin: 20px 50px;
}
.comparebtn {
    width: 250px;
    height: 50px;
    background-color: #132c8c;
    text-align: center;
}
.comparebtn:hover{
    background-color: white;
    outline: 2px solid #132c8c;
    color: #132c8c;
    transition: 0.2s ease-in-out;
}
.pricingTable {
    padding: 20px;
    width: 100%;
    margin-top: 40px;
}
table,
th,
td {
    border: 1px solid #696969;
    border-collapse: collapse;
    padding: 20px;
    text-align: center;
}
.pen-title {
    color: #1a2035;
    margin-bottom: 0;
    text-align: center;
    text-shadow: 1px 1px 0 #000;
}

.pen-description {
    color: #1a2035;
    margin-bottom: 3rem;
    text-align: center;
    text-shadow: 1px 1px 0 #000;
}

.pen-description a {
    color: #1a2035;
    text-decoration: underline;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    height: auto;
    max-width: 100%;
}

.wrap1 {
    max-width: 85.375rem;
    margin: 0 auto;
}

.image-as-background {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.screen-reader-text {
    height: auto;
    width: 1px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
    height: auto;
    width: auto;
    background-color: #000000;
    border-radius: 2px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #1a2035;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    left: 0.3125rem;
    line-height: normal;
    padding: 0.9375rem 1.4375rem 0.875rem;
    text-decoration: none;
    top: 0.3125rem;
    /* z-index: 100000; */
}

@media screen and (min-width: 64rem) {
    .wrap1 {
        padding: 1.25rem;
        margin-top: 1rem !important;
    }
}

.pricing-section {
    color: #1a2035;
}

.pricing {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 1.875rem;
    width: 100%;
}

.pricing-item {
    align-items: stretch;
    display: flex;
    flex: 0 1 20.625rem;
    flex-direction: column;
    /* position: relative; */
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
}

.pricing-feature-list {
    text-align: left;
}

.pricing-action {
    background: none;
    border: none;
    color: inherit;
}

.pricing-action:focus {
    outline: none;
}

.pricing-theme .pricing-item {
    /* background: rgba(255, 255, 255, 0.08); */
    background-color: #fff;
    border: 0.0625rem solid rgba(255, 255, 255, 0.4);
    border-radius: 0.625rem;
    color: #1a2035;
    cursor: default;
    margin-bottom: 1.25rem;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
    transition: border-color 0.3s, background 0.3s;
}

@media screen and (min-width: 40rem) {
    .pricing-theme .pricing-item {
        margin: 1.25rem;
    }
}
.pricing-theme .pricing-item:focus {
    border: 1px solid #132c8c;
}
.pricing-theme .pricing-item:hover {
    border: 0.0625rem solid white;
    background: whitesmoke;
    border: 1px solid #132c8c;
    box-sizing: border-box !important;
}

.pricing-theme .subscribe-plan {
    border: 0.0625rem solid white;
    /* background: rgb(95, 94, 94); */
    background: whitesmoke;
    border: 1px solid #132c8c;
    border: 1px solid #132c8c;
    box-sizing: border-box !important;
}

.pricing-theme .pricing-title {
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0.5rem 0;
    padding: 1.5rem;
    /* position: relative; */
}

.pricing-theme .pricing-title:after {
    position: absolute;
    bottom: 0;
    left: 35%;
    height: 0.0625rem;
    width: 30%;
    background: #132c8c;
    content: '';
}

.pricing-theme .pricing-price {
    font-size: 1.8rem;
    font-weight: 400;
    padding: 1.125rem 0 0 0;
    /* position: relative; */
    /* z-index: 100; */
}

.pricing-theme .pricing-currency {
    font-size: 1.625rem;
    vertical-align: super;
}

.pricing-theme .pricing-sentence {
    margin: 0 auto;
    padding: 0.875rem 1.25rem;
    font-size: 1rem;
}

.pricing-theme .pricing-feature-list {
    font-size: 1rem;
    list-style: none;
    margin: 0 1.875rem;
    padding: 1.875rem 0;
}

.pricing-theme .pricing-feature {
    line-height: 1.4;
}

.pricing-theme .pricing-feature:before {
    content: '✓';
    display: inline-block;
    padding: 0 0.875rem 0 0;
    vertical-align: middle;
}

.pricing-theme .pricing-action {
    background: #132c8c;
    border-radius: 0.3125rem;
    color: #fff;
    flex: none;
    font-weight: 700;
    margin: auto 1.25rem 1.25rem;
    padding: 1rem 1.25rem;
    transition: background 0.3s;
}

.pricing-theme .pricing-action:focus,
.pricing-theme .pricing-action:hover {
    background: #ffffff;
    color: #132c8c;
    border: 2px solid #132c8c;
    box-sizing: border-box !important;
}
</style>
