<template>
    <!-- form modal -->
    <div
        tabindex="0"
        @keydown.esc="toggleCreatePlan()"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showExtendPlanModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-90 pv2 center box-border" style="background: white" ref="createCategoryRef">
            <div class="box-border-bottom f3 pa3 b">Add User/Business to {{ plansName[selectedPlan] }}</div>
            <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                <!-- No of additional users -->
                <div class="flex justify-between items-center ph3 pv2">
                    <div class="flex flex-column">
                        <div class="f4">
                            <p>No of additional users</p>
                        </div>
                    </div>
                    <div class="flex flex-column">
                        <div class="pt1">
                            <div class="pv2 font-w1"><label for="name"></label></div>
                            <input
                                required
                                type="number"
                                id="additional_users"
                                name="additional_users"
                                placeholder="Enter Additional User"
                                v-model="state.additionalUsers"
                                min="0"
                            />
                        </div>
                        <div class="f3">
                            <span class="f4"
                                >({{ state.additionalUsers }}){{
                                    formatAmount(plansAdditionaUserlCost[selectedPlan] * state.additionalUsers)
                                }}</span
                            >
                            <span class="f6">/month</span>
                        </div>
                    </div>
                </div>
                <!-- No of additional business coming soon -->
                <div class="flex justify-between items-center  ph3 pv2">
                    <div class="flex flex-column">
                        <div class="f4">
                            <p>No of additional business</p>
                        </div>
                    </div>
                    <div v-if="selectedPlan == 2" class="flex flex-column">
                        <div class="pt1">
                            <div class="pv2 font-w1"><label for="name"></label></div>
                            <input
                                required
                                type="number"
                                id="additional_users"
                                name="additional_users"
                                placeholder="Enter Additional User"
                                v-model="state.additionalBusiness"
                                min="0"
                            />
                        </div>
                        <div class="f3">
                            <span class="f4"
                                >({{ state.additionalBusiness }}){{
                                    formatAmount(plansAdditionalBusinessCost[selectedPlan] * state.additionalBusiness)
                                }}</span
                            >
                            <span class="f6">/month</span>
                        </div>
                    </div>
                    <div v-else class="flex flex-column">
                        <div class="f3">
                            <span class="f6">Unlimited</span>
                        </div>
                    </div>
                </div>
                <div class="ph5">
                    <!-- Calculated Total Amount -->
                    <div class="pt1">
                        <div>
                            <div class="pv2 font-w1"><label for="name"></label></div>
                            <div class="f4">Total Amount:
                                <span v-if="selectedPlan == 2" class="f4 b text-danger">{{
                                    formatAmount(
                                        (plansAdditionaUserlCost[selectedPlan] * state.additionalUsers) +
                                        (plansAdditionalBusinessCost[selectedPlan] * state.additionalBusiness)
                                    )
                                }}</span>
                                <span v-else class="f4 b text-danger">{{
                                    formatAmount(
                                        (plansAdditionaUserlCost[selectedPlan] * state.additionalUsers)
                                    )
                                }}</span>
                            </div>
                        </div>
                        <div>
                            <div>Total Users:
                                <span v-if="selectedPlan == 2" class="f4 b text-danger">{{ state.additionalUsers + (2 * state.additionalBusiness) }}</span>
                                <span v-else class="b text-danger">{{ state.additionalUsers }}</span>
                            </div>
                        </div>
                        <div>
                            <div>Total Businesses:
                                <span v-if="selectedPlan == 2" class="f4 b text-danger">{{ state.additionalBusiness }}</span>
                                <span v-else class="b text-danger">Unlimited</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-center w-100">
                        <button class="mt4" type="submit" :disabled="state.disableSubmitButton">
                            {{ state.disableSubmitButton ? 'Processing...' : 'Make Payment' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import {
    businessPlans,
    plansName,
    plansCost,
    plansAdditionaUserlCost,
    plansAdditionalBusinessCost,
    formatAmount
} from '@/utils/lib'

export default {
    name: 'ExtendPlanModal',

    setup(props) {
        const createCategoryRef = ref(null)
        const store = useStore()
        const showExtendPlanModal = computed(() => store?.state.Settings.showExtendPlanModal)
        const selectedPlan = computed(() => store?.state.Settings.selectedPlan)
        const isTransactionDeclined = computed(() => store?.state.Settings.isTransactionDeclined)

        const state = reactive({
            additionalUsers: 0,
            additionalBusiness: 0,
            disableSubmitButton: false,
            name: ''
        })

        const toggleCreatePlan = (payload = false) => {
            store.dispatch('Settings/showExtendPlanModal', payload)
        }

        watch(
            () => state.additionalUsers,
            (curr, prev) => {
                if (curr !== prev) { 
                    if (curr === '') {
                        state.additionalUsers = 0
                    } else {
                        state.additionalUsers = parseInt(curr)
                    }
                }
            }
        )

        watch(
            () => state.additionalBusiness,
            (curr, prev) => {
                if (curr !== prev) { 
                    if (curr === '') {
                        state.additionalBusiness = 0
                    } else {
                        state.additionalBusiness = parseInt(curr)
                    }
                }
            }
        )

        const onSubmit = () => {
            state.disableSubmitButton = true

            const planData = {
                hasAdditionalUsers: state.additionalUsers > 0,
                additionalUsers: state.additionalUsers + (2 * state.additionalBusiness),
                hasAdditionalBusiness: state.additionalBusiness > 0,
                additionalBusiness: state.additionalBusiness,
                businessPlan: selectedPlan.value
            }

            store.dispatch('Settings/createPlan', planData).then(() => {
                toggleCreatePlan(false)
                state.disableSubmitButton = false
            })
        }

        // close when click outside modal

        const handleOnClickOutsideModal = e => {
            if (createCategoryRef.value && !createCategoryRef.value.contains(e.target)) {
                toggleCreatePlan()
            }
        }

        const onShowCreatePlan = (payload = true) => {
            store.dispatch('Settings/showExtendPlanModal', payload)
        }

        onMounted(() => store.dispatch('Inventory/getAllCategories'))

        return {
            state,
            showExtendPlanModal,
            toggleCreatePlan,
            onSubmit,
            createCategoryRef,
            handleOnClickOutsideModal,
            onShowCreatePlan,
            businessPlans,
            plansName,
            plansCost,
            plansAdditionaUserlCost,
            plansAdditionalBusinessCost,
            selectedPlan,
            isTransactionDeclined,
            formatAmount,
        }
    }
}
</script>

<style scoped></style>
